<template>
  <v-container>
    <h1 class="text-center">Overlays</h1>
    <v-btn
      class="mx-2"
      fab
      dark
      color="primary"
      @click="showCreateDialog"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>

    <v-row>
      <v-col v-for="overlay in overlays" :key="overlay.id" cols="2">
        <v-card
          class="mx-auto my-12"
          max-width="374"
        >
          <v-img
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
          ></v-img>

          <v-card-title>{{ overlay.name }}</v-card-title>

          <v-card-actions>
            <v-btn
              color="primary"
              @click="edit(overlay)"
            >
              bearbeiten
            </v-btn>
            <v-btn
              color="error"
              @click="deleteOverlay(overlay)"
            >
              löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Bist du dir sicher dass das Overlay "<span v-if="overlayToDelete">{{ overlayToDelete.name }}</span>" gelöscht werden soll?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Neues Overlay</v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="newOverlayName"></v-text-field>
          <v-select label="Grösse" :items="overlaySizes" item-value="id" item-text="name" v-model="selecteSize"></v-select>
          <div v-if="isCustomSize">
            <v-text-field label="Breite" type="number" v-model="customWidth"></v-text-field>
            <v-text-field label="Höhe" type="number" v-model="customHeight"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createNewOverlay()">Erstellen</v-btn>
          <v-btn color="error" @click="dialogCreate = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-container>
</template>
<script>

import BackendService from '@/services/BackendService';

export default {
  name: 'OverlayViewComponent',
  data: () => ({
    overlays: [],
    overlaySizes: [],
    dialogDelete: false,
    dialogCreate: false,
    overlayToDelete: null,
    selecteSize: null,
    newOverlayName: '',
    customWidth: 0,
    customHeight: 0,
  }),
  mounted() {
    this.loadOverlays();
    BackendService.loadOverlaySizes().then((data) => {
      this.overlaySizes = data.data;
      const defaultForCustom = this.overlaySizes.find((item) => item.isDefaultForCustom);
      this.customHeight = defaultForCustom.height;
      this.customWidth = defaultForCustom.width;
    });
  },
  computed: {
    isCustomSize() {
      return this.selectedSize !== null
        && this.overlaySizes.find((item) => item.id === this.selecteSize)
        && this.overlaySizes.find((item) => item.id === this.selecteSize).isCustom === true;
    },
  },
  methods: {
    loadOverlays() {
      BackendService.loadOverlays().then((data) => {
        this.overlays = data.data;
      });
    },
    showCreateDialog() {
      this.newOverlayName = '';
      this.selecteSize = null;
      this.dialogCreate = true;
    },
    edit(overlay) {
      this.$router.push({ name: 'editoverlay', params: { id: overlay.id } });
    },
    deleteOverlay(overlay) {
      this.overlayToDelete = overlay;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.overlayToDelete = null;
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      BackendService.deleteOverlay(this.overlayToDelete.id).then((data) => {
        this.overlays = data.data;
      });
      this.overlayToDelete = null;
      this.dialogDelete = false;
    },
    createNewOverlay() {
      BackendService.createOverlay({
        name: this.newOverlayName,
        sizeId: this.selecteSize,
        width: this.customWidth,
        height: this.customHeight,
      }).then((data) => {
        this.loadOverlays();
        this.dialogCreate = false;
        this.edit({ id: data.data.insertId });
      });
    },
  },
};
</script>
<style>
.chipGroup label {
  font-size: 12px;
}
</style>
