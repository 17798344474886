<template>
  <div ref="wrapper" class="wrapper">
    <div style="background-color: blue; position: relative; overflow: hidden;"
      :style="{
          width: `${width}px`,
          height: `${height}px`,
          zoom: zoom,
        }">
        <OverlayLayer
          v-for="(layer, index) in layers"
          :key="index"
          :index="index"
          :layer="layer"
          :edit="edit"
          :zoom="zoom"
          @update-layer="$emit('update-layer', $event)"
          @activate-layer="$emit('activate-layer', $event)"
          @deactivate-layer="$emit('deactivate-layer', $event)"
        />
      <v-menu offset-y top v-model="menuOpen" v-if="edit">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="add-button"
          v-bind="attrs"
          v-on="on"
          :style="{
            zoom: 1 / zoom,
          }">
          <v-icon large :class="{btnRotate45: menuOpen}">
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-btn @click="addLayer(item.id)">{{ item.title }}</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

      </div>
  </div>
</template>

<script>
import OverlayLayer from './OverlayLayer.vue';

export default {
  name: 'OverlayComponent',
  components: {
    OverlayLayer,
  },
  props: {
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOpen: false,
      items: [
        { id: 'alertbox', title: 'Alertbox' },
        { id: 'title', title: 'Text' },
        { id: 'image', title: 'Bild' },
        { id: 'video', title: 'Video' },
        { id: 'streamchat', title: 'Stream Chat' },
      ],
      isMounted: false,
      zoom: 0,
    };
  },
  computed: {
  },
  methods: {
    calcZoom() {
      const boundingClientRect = this.$refs.wrapper.getBoundingClientRect();
      const zoomWidth = (boundingClientRect.width - 24) / this.width;
      const zoomHeight = boundingClientRect.height / this.height;
      this.zoom = Math.min(zoomWidth, zoomHeight);
      return this.zoom;
    },
    addLayer(type) {
      this.$emit('addLayer', type);
    },
  },
  mounted() {
    this.calcZoom();
  },
  created() {
    window.addEventListener('resize', this.calcZoom);
  },
  destroyed() {
    window.removeEventListener('resize', this.calcZoom);
  },
};
</script>

<style scoped>
.wrapper {
  height: 100%;
}

.v-btn.add-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  background-color: red;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.btnRotate45 {
  transform: rotate(45deg);
}
</style>
