<template>
    <v-container>
      <h1>Rechnungsdetails</h1>

      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="billingDetails.name"
          label="Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="billingDetails.address"
          label="Adresse"
          required
        ></v-text-field>
        <v-text-field
          v-model="billingDetails.city"
          label="Stadt"
          required
        ></v-text-field>
        <v-text-field
          v-model="billingDetails.zip"
          label="PLZ"
          required
        ></v-text-field>

        <v-text-field
          v-model="billingDetails.email"
          label="E-Mail Adresse"
          type="email"
          required
        ></v-text-field>

        <h2>Zahlungsmethode</h2>
        <v-radio-group v-model="billingDetails.paymentMethod">
          <v-radio
            label="Kreditkarte"
            value="credit-card"
          ></v-radio>
          <v-radio
            label="PayPal"
            value="paypal"
          ></v-radio>
          <v-radio
            label="Sofortüberweisung"
            value="bank-transfer"
          ></v-radio>
        </v-radio-group>

        <v-btn
          color="primary"
          :disabled="!valid"
          class="mt-4"
          @click="submitOrder"
        >
          Bestellung abschließen
        </v-btn>
      </v-form>
    </v-container>
  </template>

<script>
import BackendService from '@/services/BackendService';

export default {
  data: () => ({
    valid: false,
    billingDetails: {
      name: '',
      address: '',
      city: '',
      zip: '',
      email: '',
      paymentMethod: 'credit-card',
    },
  }),
  computed: {
    totalDiscount() {
      // Getter wird hier abgerufen
      console.log(this.$store.getters.totalDiscount);
      return this.$store.getters.totalDiscount;
    },
  },
  methods: {
    submitOrder() {
      console.log('Bestellung abgesendet:', this.billingDetails);
      console.log(this.$store.state.cart);
      console.log(this.$store.getters.totalCartPrice);
      console.log(this.$store.getters.totalDiscount);

      BackendService.sendOrderConfirm({
        billingDetails: this.billingDetails,
        orderedItems: this.$store.state.cart,
        totalAmount: this.$store.getters.totalCartPrice,
        totalDiscount: this.$store.getters.totalDiscount,
        totalPriceAfterDiscount: this.$store.getters.totalPriceAfterDiscount,
      }).then((data) => {
        console.log(data.data);
      });
      this.$router.push({
        name: 'thank-you',
        params: {
          customerName: this.billingDetails.name, // Beispiel: Kundenname
          orderedItems: this.$store.state.cart, // Artikel im Warenkorb
          totalAmount: this.$store.getters.totalCartPrice, // Ursprüngliche Gesamtsumme
          totalDiscount: this.$store.getters.totalDiscount, // Gesamter Rabattbetrag
          totalPriceAfterDiscount: this.$store.getters.totalPriceAfterDiscount, // Endpreis nach Rabatt
          email: this.billingDetails.email,
        },
      });
    },
  },
};
</script>
