<template>
  <v-container class="my-5">
    <!-- Breadcrumb Navigation -->
    <v-breadcrumbs>
      <v-breadcrumbs-item :to="'/'">Home</v-breadcrumbs-item>
      <v-breadcrumbs-item :to="'/shop'">Shop</v-breadcrumbs-item>
      <v-breadcrumbs-item>{{ product?.name }}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <!-- Produkt-Details -->
    <v-row>
      <!-- Bildbereich -->
      <v-col cols="12" md="6" class="pa-0">
        <!-- Hauptbild -->
        <v-img
          :src="product?.images[activeImageIndex]"
          alt="Produktbild"
          class="rounded"
          style="width: 100%; height: auto; object-fit: contain; margin: 0;"
          @click="openLightbox(activeImageIndex)"
        ></v-img>

        <!-- Bildgalerie -->
        <v-row class="mt-4">
          <v-col
            cols="4"
            sm="3"
            md="2"
            v-for="(image, index) in product?.images"
            :key="index"
          >
            <v-card
              outlined
              :class="{ 'border-primary': activeImageIndex === index }"
              @click="setActiveImage(index)"
            >
              <v-img :src="image" height="75" contain></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Produktinformationen -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="text-h4">{{ product?.name }}</v-card-title>
          <v-card-subtitle class="text-h6 text-muted">{{ product?.shortDescription }}</v-card-subtitle>

          <v-divider class="my-4"></v-divider>

          <!-- Produktbeschreibung als HTML -->
          <v-card-text v-html="visibleDescription"></v-card-text>

          <!-- "Mehr lesen" Button -->
          <v-btn v-if="product?.description.length > 300" @click="toggleDescription" color="primary" text>
            {{ showFullDescription ? 'Weniger anzeigen' : 'Mehr lesen' }}
          </v-btn>

          <v-divider class="my-4"></v-divider>

          <div class="d-flex align-center justify-space-between">
            <div>
              <span class="text-h5 font-weight-bold">{{ product?.price }} €</span>
            </div>
            <v-btn
              color="primary"
              class="ml-4"
              @click="addToCart(product)"
              elevation="2"
            >
              <v-icon left>mdi-cart</v-icon>
              In den Warenkorb legen
            </v-btn>
          </div>

          <!-- Button zum Warenkorb -->
          <v-btn
            color="secondary"
            class="mt-4"
            :to="'/cart'"
            block
          >
            Zum Warenkorb<span v-if="cart_amount > 0"> ({{ cart_amount }})</span>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <!-- Ähnliche Produkte -->
    <v-divider class="my-8"></v-divider>
    <h2 class="text-h5 mb-4">Ähnliche Produkte</h2>
    <v-row>
      <v-col cols="12" sm="6" md="3" v-for="related in relatedProducts" :key="related.id">
        <v-card>
          <v-img :src="related.image" height="150px"></v-img>
          <v-card-title class="text-h6">{{ related.name }}</v-card-title>
          <v-card-subtitle class="text-h5 font-weight-bold">{{ related.price }} €</v-card-subtitle>
          <v-card-actions>
            <router-link :to="`/product/${related.id}`">
              <v-btn color="secondary" block>Details ansehen</v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Lightbox für Bilder -->
    <v-dialog v-model="lightbox" max-width="1200px">
      <v-card>
        <v-card-text class="d-flex justify-center">
          <v-img
            :src="product?.images[activeImageIndex]"
            style="max-width: 100%; max-height: 80vh; object-fit: contain; "
            class="rounded"
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="prevImage">Vorheriges</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="nextImage">Nächstes</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="lightbox = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar für Erfolgsmeldung -->
    <v-snackbar v-model="snackbar" color="success" timeout="3000" top>
      <span>Produkt wurde zum Warenkorb hinzugefügt!</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    product: null,
    relatedProducts: [],
    activeImageIndex: 0,
    lightbox: false,
    snackbar: false,
    showFullDescription: false, // Steuerung für "Mehr lesen"
  }),
  created() {
    const productId = this.$route.params.id;
    this.loadProduct(productId);
    this.loadRelatedProducts();
  },
  computed: {
    ...mapGetters(['cartItems']),

    // Computed Property für die sichtbare Beschreibung (mit "Mehr lesen"-Funktion)
    visibleDescription() {
      const description = this.product?.description || '';
      if (this.showFullDescription) {
        return description; // Zeigt die vollständige Beschreibung an
      }
      // Ohne das 'else', einfach die Rückgabe nach der if-Anweisung
      return description.length > 300 ? `${description.slice(0, 300)}...` : description;
    },
    cart_amount() {
      let sum = 0;
      for (let i = 0; i < this.$store.state.cart.length; i += 1) {
        sum += this.$store.state.cart[i].quantity;
      }
      return sum;
    },
  },
  methods: {
    loadProduct(id) {
      const products = [
        {
          id: 1,
          name: 'Custom Rocket League Decal',
          price: 10.99,
          description: `
            <p>Verleihe deinem Rocket League-Fahrzeug einen einzigartigen Look mit individuell gestalteten Decals!</p>
            <p>Egal, ob du deinen Wagen mit einem auffälligen Design ausstatten oder deine eigene kreative Vision umsetzen möchtest – ich fertige dir genau das Decal, das du dir vorstellst.</p>
            <ul>
              <li><strong>Exklusivität:</strong> Jedes Design wird nach deinen Wünschen erstellt – kein Decal ist wie das andere!</li>
              <li><strong>Personalisierung:</strong> Wähle Farben, Muster, Themen oder sogar dein eigenes Logo für das Design.</li>
              <li><strong>Qualität:</strong> Detailreiche und präzise Designelemente, die dein Fahrzeug in Rocket League auf ein neues Level heben.</li>
              <li><strong>Schnelligkeit:</strong> Dein individuelles Decal wird in kurzer Zeit erstellt und dir für die Nutzung zur Verfügung gestellt.</li>
            </ul>
            <h2 class="mt-5 mb-3">So funktioniert’s:</h3>
            <ol>
              <li><strong>Teile mir deine Wünsche mit:</strong> Erzähl mir, wie dein Design aussehen soll. Du kannst Farben, Themen und Stilrichtungen angeben.</li>
              <li><strong>Ich gestalte das Decal:</strong> Basierend auf deinen Vorgaben erstelle ich ein einzigartiges Design für dein Fahrzeug.</li>
              <li><strong>Anpassungen:</strong> Wenn du noch Änderungen möchtest, nehme ich sie gerne vor, bis du 100% zufrieden bist.</li>
              <li><strong>Genieße dein Design:</strong> Sobald das Decal fertig ist, erhältst du es in hoher Qualität, um es in Rocket League zu verwenden.</li>
            </ol>
            <h2 class="mt-5 mb-3">Warum du ein individuelles Decal wählen solltest:</h3>
            <ul class="mb-3">
              <li><strong>Persönlicher Touch:</strong> Dein Fahrzeug wird so einzigartig wie du!</li>
              <li><strong>Beeindrucke die Community:</strong> Mit einem selbstdesignten Decal hebst du dich von der Masse ab.</li>
              <li><strong>Perfekte Passform:</strong> Ich achte darauf, dass das Design genau zu deinem Stil und deinem Fahrzeugtyp passt.</li>
            </ul>
            <p>Lass uns gemeinsam ein Decal kreieren, das perfekt zu dir und deinem Rocket League-Fahrzeug passt. Fordere dein persönliches Design an – Ich freue mich darauf, deine Ideen zum Leben zu erwecken!</p>
          `,
          shortDescription: 'Individuell gestaltete Rocket League Decals – Dein Fahrzeug, dein Design!',
          images: ['/img/Custom_Decal/Octane_Oswin_Back.png', '/img/Custom_Decal/Octane_Oswin_Front.png', '/img/Custom_Decal/Octane_Oswin_Right.png'],
        },
        {
          id: 2,
          name: 'Custom Logo',
          price: 15.99,
          description: `
          <p>Du bist auf der Suche nach einem einzigartigen Logo, das die Identität deiner Marke perfekt widerspiegelt? Wir biete dir maßgeschneiderte Logo-Designs, die speziell nach deinen Wünschen und Anforderungen entwickelt werden.</p>

    <p>Mit unserer Expertise im Grafikdesign kreieren wir Logos, die nicht nur ästhetisch ansprechend sind, sondern auch eine starke, visuelle Botschaft für dein Unternehmen oder Projekt vermitteln. Jedes Design wird von Grund auf neu entwickelt, basierend auf deiner Vision, den Werten deines Unternehmens und deiner Zielgruppe.</p>

    <h2 class="mt-5 mb-3">Was du von uns erwarten kannst:</h2>
    <ul>
        <li>Einzigartige Designs: Jedes Logo wird individuell für dich erstellt, ohne Vorlagen oder Standardlösungen.</li>
        <li>Vielseitigkeit: Wir biete dir verschiedene Logo-Stile – von modern und minimalistisch bis hin zu verspielter und klassischer Ästhetik.</li>
        <li>Zielgruppenorientiert: Das Design wird so abgestimmt, dass es deine Zielgruppe anspricht und die Markenwerte perfekt kommuniziert.</li>
        <li>Professionelle Qualität: Wir liefere dir ein Logo, das in allen Formaten und für jedes Medium geeignet ist – sei es für Web, Print oder Social Media.</li>
        <li>Kreative Zusammenarbeit: Deine Wünsche und Ideen fließen in jeden Schritt des Designprozesses mit ein, sodass das Endergebnis genau das ist, was du dir vorstellst.</li>
    </ul>

    <h2 class="mt-5 mb-3">Der Ablauf:</h2>
    <ol class="mb-3">
        <li><strong>Beratungsgespräch</strong>: Wir besprechen deine Ideen, Wünsche und die Zielsetzung deines Logos.</li>
        <li><strong>Konzeption und Design</strong>: Wir erstelle mehrere Entwürfe und präsentiere dir verschiedene kreative Ansätze.</li>
        <li><strong>Feedback und Anpassung</strong>: Deine Meinung ist mir wichtig! Wir nehmen Anpassungen vor, bis du vollkommen zufrieden bist.</li>
        <li><strong>Finale Lieferung</strong>: Du erhältst dein Logo in allen nötigen Formaten (PNG, JPG, SVG, EPS, etc.) sowie eine kleine Anleitung zur Verwendung.</li>
    </ol>

    <p>Lass uns gemeinsam dein perfektes Logo gestalten – ein Logo, das dich und dein Unternehmen unvergesslich macht!</p>

          `,
          shortDescription: 'Individuelle Logo-Designs nach Maß',
          images: ['/img/Custom_Logo/ThoFu_Bot_Logo_transparent_text_Crop.png', '/img/Custom_Logo/DreiPrints_Wallpaper.png', '/img/Custom_Logo/Logo_Concept_final1.png'],
        },
      ];
      this.product = products.find((p) => parseInt(p.id, 10) === parseInt(id, 10));
    },
    loadRelatedProducts() {
      this.relatedProducts = [
        {
          id: 3,
          name: 'Produkt C',
          price: 8.99,
          image: '/img/c.jpg',
        },
        {
          id: 4,
          name: 'Produkt D',
          price: 12.49,
          image: '/img/d.jpg',
        },
      ];
    },
    addToCart(product) {
      this.$store.dispatch('addToCart', product);
      this.snackbar = true;
    },
    setActiveImage(index) {
      this.activeImageIndex = index;
    },
    openLightbox(index) {
      this.activeImageIndex = index;
      this.lightbox = true;
    },
    prevImage() {
      if (this.activeImageIndex > 0) {
        this.activeImageIndex -= 1;
      } else {
        this.activeImageIndex = this.product.images.length - 1;
      }
    },
    nextImage() {
      if (this.activeImageIndex < this.product.images.length - 1) {
        this.activeImageIndex += 1;
      } else {
        this.activeImageIndex = 0;
      }
    },
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
  },
};
</script>

<style scoped>
.product-description {
  font-size: 1rem; /* Sicherstellen, dass die Schriftgröße angenehm zu lesen ist */
  line-height: 1.6; /* Zeilenhöhe erhöhen für bessere Lesbarkeit */
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-description p {
  margin-bottom: 1.5em; /* Abstand zwischen Absätzen */
}

.product-description ul, .product-description ol {
  margin-left: 20px; /* Platz für Listen */
  margin-bottom: 1.5em;
}

.product-description li {
  margin-bottom: 0.5em; /* Abstand zwischen Listenelementen */
}
</style>
