import Vue from 'vue';
import VueRouter from 'vue-router';
import NewBot from '@/views/NewBot.vue';
import NewSubscription from '@/views/NewSubscription.vue';
import HomeView from '../views/HomeView.vue';
import CustomCommands from '../views/CustomCommands.vue';
import CommandSettings from '../views/CommandSettings.vue';
import DailySubGoalView from '../views/DailySubGoalView.vue';
import DailySubView from '../views/DailySubView.vue';
import DailyBitGoalView from '../views/DailyBitGoalView.vue';
import DailyBitView from '../views/DailyBitView.vue';
import TopDonoView from '../views/TopDonoView.vue';
import ObsView from '../views/ObsView.vue';
import GiveawayView from '../views/GiveawayView.vue';
import GiveawayNoPointsView from '../views/GiveawayNoPointsView.vue';
import GiveawayNoPointsEntrant from '../views/GiveawayNoPointsEntrant.vue';
import GiveawayPublicView from '../views/GiveawayPublicView.vue';
import LoyalitySettings from '../views/LoyalitySettings.vue';
import SubathonTimerView from '../views/SubathonTimerView.vue';
import CommandsView from '../views/CommandsView.vue';
import AuthView from '../views/TwitchAuthView.vue';
import ChannelPointsSettings from '../views/ChannelPointsSettings.vue';
import CommandListView from '../views/CommandListView.vue';
import Overlay from '../views/Overlay.vue';
import EditOverlay from '../views/EditOverlay.vue';
import ShowOverlay from '../views/ShowOverlay.vue';
import IntervalMsg from '../views/IntervalMsg.vue';
import LoyaltiyRewardSettings from '../views/LoyaltiyRewardSettings.vue';
import LoyaltiyRewards from '../views/LoyaltiyRewardsPublic.vue';
import LoyaltiyRewardList from '../views/LoyaltyRewardList.vue';
import ProfilSettings from '../views/ProfilSettings.vue';
import TOSView from '../views/TOSView.vue';
import ImprintView from '../views/ImprintView.vue';
import PricingView from '../views/PricingView.vue';
import FAQView from '../views/FAQView.vue';
import AboutView from '../views/AboutView.vue';
import TTCommandsView from '../views/TTCommandsView.vue';
import TTCustomCommands from '../views/TTCustomCommands.vue';
import BotState from '../views/BotState.vue';
import TwitchAuthRedirectView from '../views/TwitchAuthRedirectView.vue';
import TTLoyality from '../views/TTLoyalitySettings.vue';
import TTCommandSettings from '../views/TTCommandSettings.vue';
import MonthlySettings from '../views/MonthlySettings.vue';
import MonthlyHistoryPoints from '../views/MonthlyHistoryPoints.vue';
import ShopView from '../views/ShopView.vue';
import ProductDetail from '../views/ProductDetail.vue';
import CartView from '../views/CartView.vue';
import CheckoutView from '../views/CheckoutView.vue';
import ThankYouView from '../views/ThankYouView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    alias: '/',
    name: 'home',
    component: HomeView,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      isPublic: true,
    },
    component: AuthView,
  },
  {
    path: '/commands',
    name: 'commands',
    component: CommandsView,
  },
  {
    path: '/ccomands',
    name: 'ccomands',
    component: CustomCommands,
  },
  {
    path: '/ccs',
    name: 'ccs',
    component: CommandSettings,
  },
  {
    path: '/subathon',
    name: 'subathon',
    component: SubathonTimerView,
  },
  {
    path: '/giveawaysettings',
    name: 'giveawaysettings',
    component: GiveawayView,
  },
  {
    path: '/giveawayNoPoints',
    name: 'giveawayNoPoints',
    component: GiveawayNoPointsView,
  },
  {
    path: '/loyality',
    name: 'loyality',
    component: LoyalitySettings,
  },
  {
    path: '/cp',
    name: 'cp',
    component: ChannelPointsSettings,
  },
  {
    path: '/overlay',
    name: 'overlay',
    component: Overlay,
  },
  {
    path: '/editoverlay/:id',
    name: 'editoverlay',
    component: EditOverlay,
  },
  {
    path: '/showoverlay/:id',
    name: 'showoverlay',
    component: ShowOverlay,
  },
  {
    path: '/giveawayNoPointsEntrant/:id',
    name: 'giveawayNoPointsEntrant',
    component: GiveawayNoPointsEntrant,
  },
  {
    path: '/obs',
    name: 'obs',
    component: ObsView,
  },
  {
    path: '/interval_msg',
    name: 'interval_msg',
    component: IntervalMsg,
  },
  {
    path: '/loyality_rewards_settings',
    name: 'loyality_rewards_settings',
    component: LoyaltiyRewardSettings,
  },
  {
    path: '/bot_state',
    name: 'bot_state',
    component: BotState,
  },
  {
    path: '/loyality_reward_list',
    name: 'loyality_reward_list',
    component: LoyaltiyRewardList,
  },
  {
    path: '/profil',
    name: 'profil',
    component: ProfilSettings,
  },
  {
    path: '/newbot',
    name: 'newbot',
    component: NewBot,
  },
  {
    path: '/monthly',
    name: 'monthly',
    component: MonthlySettings,
  },
  {
    path: '/ttcommands',
    name: 'ttcommands',
    component: TTCommandsView,
  },
  {
    path: '/ttccommands',
    name: 'ttccommands',
    component: TTCustomCommands,
  },
  {
    path: '/ttloyality',
    name: 'ttloyality',
    component: TTLoyality,
  },
  {
    path: '/ttcs',
    name: 'ttcs',
    component: TTCommandSettings,
  },
  {
    path: '/mhp',
    name: 'mhp',
    component: MonthlyHistoryPoints,
  },

  // PUBLIC

  {
    path: '/giveaway/:id',
    name: 'giveaway',
    meta: {
      isPublic: true,
    },
    component: GiveawayPublicView,
  },
  {
    path: '/commandlist/:id',
    name: 'commandlist',
    meta: {
      isPublic: true,
    },
    component: CommandListView,
  },
  {
    path: '/obs/daily_sub_goal/:token',
    name: 'daily_sub_goal',
    meta: {
      isPublic: true,
      transparentBackground: true,
    },
    component: DailySubGoalView,
  },
  {
    path: '/obs/daily_subs/:token',
    name: 'daily_subs',
    meta: {
      isPublic: true,
      transparentBackground: true,
    },
    component: DailySubView,
  },
  {
    path: '/obs/daily_bit_goal/:token',
    name: 'daily_bit_goal',
    meta: {
      isPublic: true,
      transparentBackground: true,
    },
    component: DailyBitGoalView,
  },
  {
    path: '/obs/daily_bits/:token',
    name: 'daily_bits',
    meta: {
      isPublic: true,
      transparentBackground: true,
    },
    component: DailyBitView,
  },
  {
    path: '/obs/top_dono/:token',
    name: 'top_dono',
    meta: {
      isPublic: true,
      transparentBackground: true,
    },
    component: TopDonoView,
  },
  {
    path: '/loyality_rewards/:id',
    name: 'loyality_rewards',
    meta: {
      isPublic: true,
    },
    component: LoyaltiyRewards,
  },
  {
    path: '/impressum',
    name: 'imprint',
    meta: {
      isPublic: true,
    },
    component: ImprintView,
  },
  {
    path: '/agb',
    name: 'tos',
    meta: {
      isPublic: true,
    },
    component: TOSView,
  },
  {
    path: '/function',
    name: 'pricing',
    meta: {
      isPublic: true,
    },
    component: PricingView,
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      isPublic: true,
    },
    component: ShopView,
  },
  {
    path: '/product/:id',
    name: 'productdetail',
    meta: {
      isPublic: true,
    },
    component: ProductDetail,
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      isPublic: true,
    },
    component: CartView,
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: {
      isPublic: true,
    },
    component: CheckoutView,
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    meta: {
      isPublic: true,
    },
    component: ThankYouView,
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
      isPublic: true,
    },
    component: FAQView,
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      isPublic: true,
    },
    component: AboutView,
  },
  {
    path: '/neues_abo/:id',
    name: 'new_subscription',
    meta: {
      isPublic: true,
    },
    component: NewSubscription,
  },
  {
    path: '/twitch_auth/:id',
    name: 'twitch_auth',
    meta: {
      isPublic: true,
    },
    component: TwitchAuthRedirectView,
  },
  {
    path: '/neues_abo/:id/twitch',
    name: 'new_subscription_twitchconnect',
    meta: {
      isPublic: true,
    },
    component: NewSubscription,
  },
  {
    path: '/neues_abo/:id/spotify',
    name: 'new_subscription_spotifyconnect',
    meta: {
      isPublic: true,
    },
    component: NewSubscription,
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
