<template>
  <v-container>
    <v-row>
      <v-col
        v-for="product in products"
        :key="product.id"
        cols="12" sm="6" md="3"
      >
        <v-card>
          <!-- Bild ohne Zuschneiden und mit richtiger Skalierung -->
          <v-img
            :src="product.image"
            height="250px"
            contain
          ></v-img>

          <v-card-title>{{ product.name }}</v-card-title>
          <v-card-subtitle>{{ product.price }} €</v-card-subtitle>

          <v-card-actions>
            <router-link :to="`/product/${product.id}`">
              <v-btn color="primary">Details ansehen</v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    products: [
      {
        id: 1, name: 'Custom Rocket League Decal', price: 10.99, image: '/img/Custom_Decal/Octane_Oswin_Front.png',
      },
      {
        id: 2, name: 'Custom Logo', price: 15.99, image: '/img/Custom_Logo/ThoFu_Bot_Logo_transparent_text_Crop.png',
      },
    ],
  }),
};
</script>
