<template>
    <vue-draggable-resizable
        :x="layer.x"
        :y="layer.y"
        :w="layer.width"
        :h="layer.height"
        :scale="zoom"
        :active="layer.active"
        class="overlay-layer"
        :class="{ bordered: edit }"
        @resizing="onResizing"
        @dragging="onDragging"
        @activated="onActivated"
        @deactivated="onDeactivated"
        v-if="zoom"
        >
        sadf
        {{ layer }}
    </vue-draggable-resizable>
</template>
<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

export default {
  name: 'OverlayComponent',
  components: {
    VueDraggableResizable,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
    },
    layer: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onResizing(x, y, width, height) {
      this.$emit('update-layer', {
        data: {
          ...this.layer, x, y, width, height,
        },
        index: this.index,
      });
    },
    onDragging(x, y) {
      this.$emit('update-layer', {
        data: {
          ...this.layer, x, y,
        },
        index: this.index,
      });
    },
    onActivated() {
      this.$emit('activate-layer', this.index);
    },
    onDeactivated() {
      this.$emit('deactivate-layer', this.index);
    },
  },
};
</script>
<style scoped>
.overlay-layer {
  position: absolute;
  display: block;
}

.bordered {
  border: 3px dotted black;
}
</style>
