<template>
  <v-container>
    <h1>Warenkorb</h1>

    <v-list>
      <v-list-item
        v-for="item in cartItems"
        :key="item.id"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.shortDescription }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ item.price.toFixed(2) }} €</v-list-item-subtitle>
        </v-list-item-content>

        <!-- Produktanzahl, Rabatt und Gesamtsumme -->
        <v-list-item-action class="d-flex flex-column align-end">
          <div class="d-flex align-center">
            <v-btn icon color="primary" @click="increaseQuantity(item.id)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span class="mx-2">{{ item.quantity }}</span>
            <v-btn icon color="red" @click="decreaseQuantity(item.id)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!-- Divider -->
    <v-divider class="my-4"></v-divider>

    <!-- Gesamtsumme -->
    <div class="d-flex justify-space-between align-center">
      <h2>Gesamt:</h2>
      <h2>{{ totalPrice.toFixed(2) }} €</h2>
    </div>

<!-- Gesamtsumme der Rabatte -->
<div v-if="totalDiscount > 0" class="d-flex justify-space-between align-center mt-2 text-success">
  <h3>Mengenrabatt:</h3>
  <h3>
    -{{ totalDiscount.toFixed(2) }} €
    ({{ highestDiscountPercentage }}%)
  </h3>
</div>

    <!-- Endpreis nach Rabatt -->
    <div v-if="totalDiscount > 0" class="d-flex justify-space-between align-center mt-2">
      <h3>Endpreis nach Rabatt:</h3>
      <h3>{{ totalPriceAfterDiscount.toFixed(2) }} €</h3>
    </div>

    <!-- Warenkorb leeren und Weiter Button -->
    <div class="d-flex justify-end align-center mt-4">
      <v-btn
        color="error"
        @click="clearCartHandler"
        v-if="cartItems.length > 0"
      >
        Warenkorb leeren
      </v-btn>
      <v-btn
        color="primary"
        @click="goToCheckout"
        :disabled="cartItems.length === 0"
        class="ml-4"
      >
        Weiter
      </v-btn>
    </div>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" color="success" timeout="3000" top>
      <span>{{ snackbarMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    snackbar: false,
    snackbarMessage: '',
  }),
  computed: {
    ...mapGetters(['cartItems', 'totalCartPrice']),
    totalQuantity() {
      // Berechnet die Gesamtanzahl aller Produkte im Warenkorb
      return this.cartItems.reduce((total, item) => total + item.quantity, 0);
    },
    highestDiscountPercentage() {
      // Rabatt basierend auf der Gesamtanzahl der Produkte
      if (this.totalQuantity >= 5) return 15;
      if (this.totalQuantity >= 4) return 10;
      if (this.totalQuantity >= 3) return 5;
      return 0;
    },
    totalDiscount() {
      // Berechnet den gesamten Rabatt auf Basis des höchsten Prozentsatzes
      const discountPercentage = this.highestDiscountPercentage / 100;
      return this.cartItems.reduce(
        (acc, item) => acc + item.price * item.quantity * discountPercentage,
        0,
      );
    },
    totalPrice() {
      // Gesamtpreis ohne Rabatt
      return this.cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0,
      );
    },
    totalPriceAfterDiscount() {
      // Gesamtpreis nach Abzug des Rabatts
      return this.totalPrice - this.totalDiscount;
    },
  },
  methods: {
    ...mapActions(['removeFromCart', 'clearCart', 'decrementQuantity', 'addToCart', 'updateDiscountValues']),
    increaseQuantity(productId) {
      const product = this.cartItems.find((item) => item.id === productId);
      if (product) {
        this.addToCart(product);
      }
    },
    decreaseQuantity(productId) {
      this.decrementQuantity(productId);
      this.snackbarMessage = 'Artikelmenge wurde verringert!';
      this.snackbar = true;
    },
    clearCartHandler() {
      this.clearCart();
      this.snackbarMessage = 'Der Warenkorb wurde geleert!';
      this.snackbar = true;
    },
    goToCheckout() {
      console.log((this.totalPrice - this.totalDiscount).toFixed(2));
      this.updateDiscountValues({
        totalDiscount: this.totalDiscount.toFixed(2),
        totalPriceAfterDiscount: (this.totalPrice - this.totalDiscount).toFixed(2),
        highestDiscountPercentage: this.highestDiscountPercentage.toFixed(2),
      });
      this.$router.push('/checkout'); // Leitet zur Checkout-Seite
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.mx-2 {
  margin: 0 8px;
}
.mt-2 {
  margin-top: 8px;
}
.ml-4 {
  margin-left: 16px;
}
.text-success {
  color: #4caf50;
}
</style>
