var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"wrapper"},[_c('div',{staticStyle:{"background-color":"blue","position":"relative","overflow":"hidden"},style:({
        width: `${_vm.width}px`,
        height: `${_vm.height}px`,
        zoom: _vm.zoom,
      })},[_vm._l((_vm.layers),function(layer,index){return _c('OverlayLayer',{key:index,attrs:{"index":index,"layer":layer,"edit":_vm.edit,"zoom":_vm.zoom},on:{"update-layer":function($event){return _vm.$emit('update-layer', $event)},"activate-layer":function($event){return _vm.$emit('activate-layer', $event)},"deactivate-layer":function($event){return _vm.$emit('deactivate-layer', $event)}}})}),(_vm.edit)?_c('v-menu',{attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-button",style:({
          zoom: 1 / _vm.zoom,
        })},'v-btn',attrs,false),on),[_c('v-icon',{class:{btnRotate45: _vm.menuOpen},attrs:{"large":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,4244538424),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-btn',{on:{"click":function($event){return _vm.addLayer(item.id)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }