<template>
    <div>
    <v-list flat>
      <v-subheader>Layers</v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in layers"
          :key="i"
          @click="$emit('activate-layer', i)"
        >
          <v-list-item-icon>
            <v-icon v-text="iconForType(item.type)"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.type"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</div>
</template>

<script>
export default {
  name: 'OverlayListComponent',
  props: {
    layers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
  },
  methods: {
    setActive(index) {
      this.selectedItem = index;
    },
    iconForType(type) {
      switch (type) {
        case 'alertbox':
          return 'mdi-alert-circle';
        case 'title':
          return 'mdi-format-title';
        case 'image':
          return 'mdi-image';
        case 'video':
          return 'mdi-video';
        case 'streamchat':
          return 'mdi-comment-text-outline';
        default:
          return 'mdi-help';
      }
    },
  },
};
</script>
<style scoped>
</style>
