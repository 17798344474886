<template>
    <v-container>
      <h1>Vielen Dank für Ihre Bestellung!</h1>

      <v-divider class="my-4"></v-divider>

      <v-row>
        <v-col cols="12" sm="6">
          <h3>Bestellübersicht</h3>

          <!-- Kundenname -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><strong>Name:</strong> {{ customerName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Bestellitems -->
          <v-list>
            <v-list-item
              v-for="item in orderedItems"
              :key="item.id"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.quantity }} x {{ item.price.toFixed(2) }} €</v-list-item-subtitle>
                <v-list-item-subtitle><strong>Gesamt: </strong>{{ (item.price * item.quantity).toFixed(2) }} €</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- Gesamtsumme -->
          <v-divider class="my-4"></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-if="totalDiscount > 0"><strong>Zwischensumme: </strong>{{ totalAmount.toFixed(2) }} €</v-list-item-title>
              <v-list-item-title v-if="totalDiscount > 0"><strong>Rabatt: </strong>{{ totalDiscount }} € </v-list-item-title>
              <v-list-item-title v-else>
                <strong>Gesamt: </strong>{{ totalPriceAfterDiscount }} €
              </v-list-item-title>
              <v-list-item-title v-if="totalDiscount > 0">
                <strong>Gesamt: </strong>{{ totalPriceAfterDiscount }} €
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6">
          <h3>Bestellbestätigung</h3>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Ihre Bestellung wurde erfolgreich aufgegeben!</v-list-item-title>
                <v-list-item-subtitle>Eine Bestellbestätigung wurde an Ihre E-Mail-Adresse gesendet.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-btn color="primary" @click="goToHome">Zurück zur Startseite</v-btn>
    </v-container>
  </template>

<script>
export default {
  data() {
    return {
      customerName: '',
      orderedItems: [],
      totalAmount: 0,
      totalDiscount: 0,
    };
  },
  created() {
    // Hier könntest du die Bestellinformationen aus dem Store oder von einem Backend abrufen
    this.customerName = this.$route.params.customerName || 'Kunde';
    this.orderedItems = this.$route.params.orderedItems || [];
    this.totalAmount = this.$route.params.totalAmount || 0;
    this.totalDiscount = this.$route.params.totalDiscount || 0;
    this.totalPriceAfterDiscount = this.$route.params.totalPriceAfterDiscount || 0;
    console.log(this.$route.params.totalDiscount);

    this.clearCart();
  },
  methods: {
    clearCart() {
      this.$store.commit('clearCart');
    },
    goToHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
  .my-4 {
    margin: 16px 0;
  }
</style>
