<template>
  <v-container fill-height class="wider">
    <div class="d-flex" style="justify-content: space-between; width: 100%; align-items: center;">
      <div class="d-flex" style="justify-content: space-between; align-items: center; gap: 10px">
        <v-btn class="primary" @click="back()">&lt;</v-btn>
        <v-text-field v-model="overlay.name" class="namefield" autocomplete="off"></v-text-field>
      </div>
      <v-btn class="primary" @click="save">Speichern</v-btn>
    </div>
    <v-row no-gutters class="fill-most">
      <v-col cols="3" style="background-color: #1E1E1E">
        <OverlayListComponent
          :layers="overlay.layers"
          v-if="overlay.layers"
          ref="overlayList"
          @activate-layer="activateLayer"
        />
      </v-col>
      <v-col cols="9">
        <OverlayComponent
          v-if="overlay.id"
          :width="overlay.width"
          :height="overlay.height"
          :edit="true"
          :layers="overlay.layers"
          @addLayer="addLayer"
          @update-layer="updateLayer"
          @activate-layer="activateLayer"
          @deactivate-layer="deactivateLayer"
          />
      </v-col>
    </v-row>
    <!-- Snackbar für Erfolgsmeldung -->
    <v-snackbar v-model="snackbar" color="success" timeout="3000" top>
      <span>Speichern erfolgreich!</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>

import BackendService from '@/services/BackendService';
import OverlayComponent from '@/components/OverlayComponent.vue';
import OverlayListComponent from '@/components/OverlayListComponent.vue';

export default {
  name: 'OverlayEditComponent',
  components: { OverlayComponent, OverlayListComponent },
  data: () => ({
    overlay: {},
    originalOverlay: {},
    snackbar: false,
  }),
  mounted() {
    BackendService.loadOverlay(this.$router.currentRoute.params.id).then((data) => {
      this.overlay = data.data;
      const layers = [];
      this.overlay.layers.forEach((layer) => {
        layers.push({
          ...layer,
          active: false,
        });
      });
      this.overlay.layers = layers;
      this.originalOverlay = JSON.parse(JSON.stringify(this.overlay));
    });
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges()) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('You have unsaved changes. Are you sure you want to leave?')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    activateLayer(index) {
      this.overlay.layers[index].active = true;
      this.$refs.overlayList.setActive(index);
    },
    deactivateLayer(index) {
      this.overlay.layers[index].active = false;
      this.$refs.overlayList.setActive(null);
    },
    addLayer(type) {
      this.overlay.layers.push({
        type, width: 200, height: 200, x: 0, y: 0, active: false,
      });
    },
    back() {
      if (this.hasUnsavedChanges()) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('You have unsaved changes. Are you sure you want to leave?')) {
          this.$router.push({ name: 'overlay' });
        }
      } else {
        this.$router.push({ name: 'overlay' });
      }
    },
    updateLayer(event) {
      this.overlay.layers[event.index].x = event.data.x;
      this.overlay.layers[event.index].y = event.data.y;
      this.overlay.layers[event.index].height = event.data.height;
      this.overlay.layers[event.index].width = event.data.width;
      /*
      const index = this.overlay.layers.findIndex((l) => l === layer);
      this.overlay.layers[index] = {
        ...layer, x, y, width, height,
      };
      */
    },
    save() {
      BackendService.saveOverlay(this.getOverlayData()).then((data) => {
        this.overlay = data.data;
        this.snackbar = true;
        this.originalOverlay = JSON.parse(JSON.stringify(this.overlay));
      });
    },
    getOverlayData() {
      return {
        id: this.overlay.id,
        name: this.overlay.name,
        layers: this.overlay.layers,
      };
    },
    hasUnsavedChanges() {
      console.log(JSON.stringify(this.overlay));
      console.log(JSON.stringify(this.originalOverlay));
      return JSON.stringify(this.overlay) !== JSON.stringify(this.originalOverlay);
    },
    handleBeforeUnload(event) {
      if (this.hasUnsavedChanges()) {
        event.preventDefault();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fill-most {
  height: calc(100% - 50px)
}

.wider {
  max-width: 99vw !important;
}

.namefield {
  max-width: 400px;
}

.namefield::v-deep input {
  font-size: 1.5em !important;
  max-height: 1.5em !important;
  line-height: 1.5em !important;
}

</style>
