<template>
  <div>
    <div class="d-flex justify-center align-center">
    <v-btn class="v-size--large" color="success" @click="openDialog({
      max_per_stream_is_enabled: 0,
      max_per_user_per_stream_is_enabled: 0,
      should_redemptions_skip_request_queue: 0,
      is_user_input_required: 0,
      is_enabled: 0,
      is_vip: 0,
    })">Reward erstellen</v-btn>
  </div>
    <div class="channelPointsWrapper"
         :class="{ drawerOpen : !$store.state.navigation_drawer_mini }">
    <v-row>
      <v-col
        v-for="item in sortedRewards"
        :key="item.id"
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <v-card outlined >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-h6 mb-4">{{ item.title }}</div>
              <v-list-item-title class="text-overline mb-2">{{ item.prompt }}</v-list-item-title>
              <v-list-item-subtitle>Reward Kosten: {{ item.cost }}</v-list-item-subtitle>
              <v-btn :color="item.is_bot_owner === 0 ? 'gray' : 'primary'"
                     dark @click="openDialog(item)">
                Belohnung bearbeiten
              </v-btn>
            </v-list-item-content>
            <v-list-item-avatar tile size="80" :color="item.bg_color">
              <img :src="getImage(item.img)" alt="Avatar" class="rewardimg">
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    </div>

    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="error"
              @click="deleteReward"
              :hidden="selectedReward.id === undefined"
            >
              Delete
            </v-btn>
            <v-btn
              color="success"
              @click="saveChanges"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>User Controls</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.title"
                label="Name des Rewards"
                outlined
                counter="45"
                maxLength="45"
                required
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-text-field>
            </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.cost"
                label="Kosten"
                outlined
                type="Number"
                required
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-textarea
                v-model="selectedReward.prompt"
                label="Beschreibung"
                rows="1"
                auto-grow
                outlined
                counter="200"
                maxLength="200"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-textarea>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content v-if="selectedReward.id">
              <v-switch
                v-model="selectedReward.is_paused"
                label="Belohnung in Pause versetzen"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.should_redemptions_skip_request_queue"
                label="Belohnungsanfrage überspringen"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.is_user_input_required"
                label="Ist eine Usereingabe erforderlich?"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.is_enabled"
                label="Belohung ist sichtbar in den Kanalpunkten"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.max_per_stream_is_enabled"
                label="Maximale Einlösungen pro Stream aktivieren"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.max_per_stream"
                label="Maximale Einlösungen pro Stream"
                outlined
                type="Number"
                :disabled="selectedReward.is_bot_owner === 0 ||
                selectedReward.max_per_stream_is_enabled === 0"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-switch
                v-model="selectedReward.max_per_user_per_stream_is_enabled"
                label="Maximale Einlösungen pro Stream pro User aktivieren"
                color="success"
                :true-value="1"
                :false-value="0"
                hide-details
                @change="saveSwitchState(selectedReward)"
                :disabled="selectedReward.is_bot_owner === 0"
              ></v-switch>
            </v-list-item-content>
            <v-list-item-content>
              <v-text-field
                v-model="selectedReward.max_per_user_per_stream"
                label="Maximale Einlösungen pro Stream pro User"
                outlined
                type="Number"
                :disabled="selectedReward.is_bot_owner === 0 ||
                selectedReward.max_per_user_per_stream_is_enabled === 0"
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-select
                    v-model="selectedReward.cp_type"
                    item-text="title"
                    :items="cp_types"
                    label="Reward für:"
                    clearable
                    ></v-select>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-text-field
                v-if="selectedReward.cp_type === 4"
                v-model="selectedReward.cp_lpv"
                label="Loyalitiy Points pro Einlösung"
                outlined
                type="Number"
                :disabled="selectedReward.cp_type !== 4"
              ></v-text-field>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showNotification"
      max-width="600"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="showNotification = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Fehler!!!</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12" style="color: red;">Leider kann diese Kanalpunktebelohnung
           nicht editiert werden! Der Grund dafür ist das diese
           Belohnung nicht vom Dashboard aus erstellt wurde!</div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">Bist du dir sicher das diese
          Kanalpunktebelohnung gelöscht werden soll?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Nein!</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja!</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BackendService from '@/services/BackendService';

export default {
  data() {
    return {
      dialogDelete: false,
      showDialog: false,
      selectedIndex: 0,
      reward: [],
      selectedReward: {
        id: '',
        title: '',
        prompt: '',
        is_bot_owner: '',
        cost: '',
        is_paused: '',
        should_redemptions_skip_request_queue: '',
        is_user_input_required: '',
        max_per_stream_is_enabled: '',
        max_per_stream: '',
        max_per_user_per_stream_is_enabled: '',
        max_per_user_per_stream: '',
        is_enabled: '',
        cp_lpv: '',
      },
      cp_types: [
        {
          value: 1,
          title: 'zum zusammen zocken',
        },
        {
          value: 2,
          title: 'eine weitere beliebige Warteschlange',
        },
        {
          value: 3,
          title: 'Spotify Songrequest',
        },
        {
          value: 4,
          title: 'Kanalpunkte gegen Loyality Points tauschen',
        },
        // {
        //   value: 5,
        //   title: 'vipForTime',
        // },
        {
          value: 6,
          title: 'zum Skippen des aktuellen Songs',
        },
      ],
      showNotification: false,
    };
  },
  mounted() {
    BackendService.loadRewards().then((data) => {
      this.reward = data.data;
      // console.log(this.reward);
    });
  },
  computed: {
    sortedRewards() {
      const sortedArray = this.reward.slice();
      sortedArray.sort((a, b) => {
        if (a.cost !== b.cost) {
          return a.cost - b.cost;
        }
        return a.title.localeCompare(b.title);
      });

      return sortedArray;
    },
  },
  methods: {
    getImage(img) {
      try {
        const data = JSON.parse(img);
        return data.url_1x;
      } catch (e) {
        return img;
      }
    },
    openDialog(item) {
      if (item.is_bot_owner === 0) {
        this.showNotification = true;
        return;
      }

      this.selectedReward = { ...item };
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    saveChanges() {
      if (this.selectedReward.id) {
        // Item bearbeiten
        BackendService.updateReward(this.selectedReward).then((data) => {
          this.reward = data.data;
          this.showDialog = false;
        });
      } else {
        // Neues Item hinzufügen
        BackendService.createReward(this.selectedReward).then((data) => {
          this.reward = data.data;
          this.showDialog = false;
        });
      }
    },
    saveSwitchState(item) {
      if (item.is_paused) {
        this.selectedReward.is_paused = 1;
      } else {
        this.selectedReward.is_paused = 0;
      }
      if (item.is_vip) {
        this.selectedReward.is_vip = 1;
      } else {
        this.selectedReward.is_vip = 0;
      }
      if (item.is_enabled) {
        this.selectedReward.is_enabled = 1;
      } else {
        this.selectedReward.is_enabled = 0;
      }
      if (item.is_user_input_required) {
        this.selectedReward.is_user_input_required = 1;
      } else {
        this.selectedReward.is_user_input_required = 0;
      }
      if (item.max_per_user_per_stream_is_enabled) {
        this.selectedReward.max_per_user_per_stream_is_enabled = 1;
      } else {
        this.selectedReward.max_per_user_per_stream_is_enabled = 0;
      }
      if (item.max_per_stream_is_enabled) {
        this.selectedReward.max_per_stream_is_enabled = 1;
      } else {
        this.selectedReward.max_per_stream_is_enabled = 0;
      }
      if (item.should_redemptions_skip_request_queue) {
        this.selectedReward.should_redemptions_skip_request_queue = 1;
      } else {
        this.selectedReward.should_redemptions_skip_request_queue = 0;
      }
    },
    deleteReward() {
      // console.log(this.selectedReward.id);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      // console.log(this.selectedReward.id);
      // console.log('LÖSCHEN!!!');
      BackendService.deleteReward(this.selectedReward).then((data) => {
        this.dialogDelete = false;
        this.showDialog = false;
        this.reward = data.data;
      });
    },
    closeDelete() {
      // console.log(this.selectedReward.id);
      // console.log('NICHT LÖSCHEN!!!');
      this.dialogDelete = false;
    },
  },
};
</script>
<style>
.channelPointsWrapper {
  position: relative;
  left: 20px;
  top: 10px;
  bottom: 0;
  padding-bottom: 30px;
  width: calc(100% - 100px);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: width;

  &.drawerOpen {
    width: calc(100% - 300px);
  }
}

.v-avatar.rounded-0 {
  border-radius: 4px !important;
}

.v-avatar img.rewardimg {
  width: 28px;
  height: 28px;
}

</style>
